import 'imagesloaded';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import 'select2/dist/css/select2.css'; 
import 'select2';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
import 'magnific-popup'; 
import 'magnific-popup/dist/magnific-popup.css'; 

var selectedImages = [];
var downloadMultipleForm = $('#download_multiple_type');

$(document).ready(function(){

	/* Load Cart */
	loadCart();
	
	/* Load Tooltip */
	$('[data-toggle="tooltip"]').tooltip();
	
	/* Load Required star */
	showRequireStar();
	
	//Load Carousel
	loadCarousel();	
		
	//Load Sidebar Menu
	loadToggleMenu();
	
	//Load Input Focus Line
	loadInputFocusLine();
	
	//Load Select2
	loadSelect2(); 
	
	//Ajax load Modal
	loadAjaxModal();
	
	//Load Masonry
	loadMasonry(); 
	
	//Load Datepicker
	loadDatePicker();
	
	//Select Image
	$('.masonry-item .picture-action.select').on('click', function(){
		var image = $(this).parent().data('target');
		var index = selectedImages.indexOf(image);
		 
		if(index == -1)
			selectImage(image);
		if(index > -1)
			unSelectImage(image);		
	});
	
	$('#selectAll').on('change', function(){
		if($('#selectAll').prop("checked") == true){
			$(".masonry-list .masonry-item").each(function(index) {
				var image = $(this).data('target');
				selectImage(image);
			});
		} else {
			$(".masonry-list .masonry-item").each(function(index) {
				var image = $(this).data('target');
				unSelectImage(image);
			});
		}
	});		
	
	//Init
	loadDocumentFrontendFiltersOptions();
	
	//Load Document Filters Options
	$('#document_frontend_filter').on('change', 'select', function(){
		loadDocumentFrontendFiltersOptions();
	});
	
	$('#document_frontend_filter').on('click', '.select2-selection__clear', function(){
		loadDocumentFrontendFiltersOptions();
	});
});

function loadCart()
{
	$.ajax({
		type: "GET",
		url: Routing.generate('frontend_cart_index'),
		dataType: 'json',
		success : function(result, status){
			$('#cart').html(result.cartHtml.content);
			updateCartQuantity(result.cartCount, true);
		},
		error : function(result, status, error){
			$('#cart').html('<p>Erreur de chargement du panier</p>');
		}
	});
	
	$('#cart-link').click(function(){
		$('#cart').toggleClass('open');
	});
	
	$('body').on('click', '.add-cart-document', function(e){ 
		e.preventDefault();
		$.ajax({
			type: "GET",
			url: Routing.generate('frontend_cart_add', {'id': $(this).data('id')}),
			dataType: 'json',
			context: $(this),
			success : function(result, status){
				$('#cart').html(result.cartHtml.content);
				updateCartQuantity(result.cartCount, false);
				
				$('#item-'+this.data('id')+' .delete-cart-document').fadeIn(300);
				$('#item-'+this.data('id')+' .add-cart-document').hide();
			},
			error : function(result, status, error){
				$('#cart').html('<p>Erreur de chargement du panier</p>');
			}
		});
	}); 
	
	$('body').on('click', '.delete-cart-document', function(e){ 
		e.preventDefault();
		$.ajax({
			type: "GET",
			url: Routing.generate('frontend_cart_delete', {'id': $(this).data('id')}),
			dataType: 'json',
			context: $(this),
			success : function(result, status){
				$('#cart').html(result.cartHtml.content);
				updateCartQuantity(result.cartCount, false);
				
				$('#item-'+this.data('id')+' .add-cart-document').fadeIn(300);
				$('#item-'+this.data('id')+' .delete-cart-document').hide();
			},
			error : function(result, status, error){
				$('#cart').html('<p>Erreur de chargement du panier</p>');
			}
		});
	}); 
	
	$('body').on('click', '#delete-cart-document', function(e){ 
		$.ajax({
			type: "GET",
			url: Routing.generate('frontend_cart_delete_all'),
			dataType: 'json',
			success : function(result, status){
				$('#cart').html(result.cartHtml.content);
				updateCartQuantity(result.cartCount, false);
				document.location.reload();
			},
			error : function(result, status, error){
				$('#cart').html('<p>Erreur de chargement du panier</p>');
			}
		});
	}); 
}

function updateCartQuantity(value, init)
{
	if(init == false)
	{
		$('#cart-counter').addClass('change');
		$('#cart-counter').text(value);
		
		setInterval(function(){ 
			$('#cart-counter').removeClass('change');
  		}, 2000);
  	}
	if(init == true)
	{
  		$('#cart-counter').text(value);
  	}
}

$(document).mouseup(function(e) 
{
    if (!$('#cart').is(e.target) && $('#cart').has(e.target).length === 0) 
        $('#cart').removeClass('open');
});
	
function selectImage(image)
{	
	var index = selectedImages.indexOf(image);
	var prototype = $(downloadMultipleForm).find('.documentPrototype').data('prototype');
	var input = prototype.replace(/__name__/g, image);
	input = input.replace(/>/g, ' value="'+image+'">');
	
	if(index == -1)
	{
		downloadMultipleForm.append(input);
		
		selectedImages.push(image);
		$("#picture-"+image).addClass('selected');
		
	}
	
	changeSelectLabel();
	changeButtonStatus();
}

function unSelectImage(image)
{	
	var index = selectedImages.indexOf(image);
	
	if(index > -1)
	{
		if($('#download_multiple_type_documents_'+image).length > 0)
			$('#download_multiple_type_documents_'+image).remove();
			
		selectedImages.splice(index, 1);
		$("#picture-"+image).removeClass('selected');
		
		$("#picture-"+image+' .picture-action .fa-times').hide();
		$("#picture-"+image+' .picture-action .fa-check').show();
	}
	
	changeSelectLabel();
	changeButtonStatus();
}

function changeSelectLabel()
{
	$('#selectNumberPhotoLabel #numberPhotoLabel').text(selectedImages.length);
	if(selectedImages.length > 1) 
	{
		$('#selectNumberPhotoLabel #singularPhotoLabel').hide();
		$('#selectNumberPhotoLabel #pluralPhotoLabel').show();
	} else {
		
		$('#selectNumberPhotoLabel #singularPhotoLabel').show();
		$('#selectNumberPhotoLabel #pluralPhotoLabel').hide();
	}
}

function changeButtonStatus()
{
	if(selectedImages.length > 0) 
		$('#download_multiple_type button').removeAttr('disabled');
	else
		$('#download_multiple_type button').attr('disabled', 'disabeld');
}

function loadDatePicker()
{	
	$.fn.datepicker.dates['fr'] = {
	    days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
	    daysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
	    daysMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"], 
	    months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
	    monthsShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Jui", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
	    today: "Aujourd'hui",
	    clear: "Effacer",
	    format: "dd/mm/yyyy",
	    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
	    weekStart: 0
	};
	
	$('.datepicker-input').datepicker({
		language: _locale,
		format: 'dd/mm/yyyy',
		autoclose: true
	});
}

function loadAjaxModal()
{
	 $('a.playAjax').magnificPopup({
		type: 'ajax',
		ajax:{
			settings: null,
		},
		callbacks: {
			ajaxContentAdded: function() {
				$('.mfp-content').imagesLoaded( function() {
					$('.mfp-content .loader').hide();
					$('.mfp-content img').fadeIn(500);
				});
			}
		},
		gallery:{
			enabled:true
		}
	});
}

function loadMasonry()
{
	var jQueryBridget = require('jquery-bridget');
	var Masonry = require('masonry-layout');
	jQueryBridget('masonry', Masonry, $);
	
	$('.masonry-list').imagesLoaded( function() {
		$('.masonry-list').masonry({
			itemSelector: '.masonry-item',
			gutter: 20
		});
	});
}
 
function loadSelect2()
{
	$('select').select2({
		  placeholder: 'Select an option',
		  allowClear: true,
	});
}

function loadInputFocusLine()
{
	$('input.form-control').focus(function(){
		$(this).parent().addClass('focus');
	});
	
	$('input.form-control').blur(function(){
		$(this).parent().removeClass('focus');
	});
}


function loadCarousel()
{
	//Init
	$('.owl-carousel').owlCarousel({
		loop: false,
		margin: 25,
		nav: false,
		autoWidth: true,
		dots: false
	});
	
	//Navigation
	$('.nav-carousel-slide-next').each(function(){
		var y = $($(this).data('target')).offset().top;
		$(this).css('top', y);
	});
	
	$('.nav-carousel-prev').click(function(){
		$($(this).data('target')).trigger('prev.owl.carousel');
	});
	
	$('.nav-carousel-next, .nav-carousel-slide-next').click(function(){
		$($(this).data('target')).trigger('next.owl.carousel');
	});
}

function loadToggleMenu()
{					   
	$('.left-sidebar-wrapper ul.nav .nav-item > a').click(function(){
		$(this).parent().toggleClass('toggle');
		$(this).parent().find('.sub-menu').slideToggle("fast");
	});
}

function removeArrayValue(removeItem, array)
{
	array = jQuery.grep(array, function(value) {
	  return value != removeItem;
	});
} 

function showRequireStar()
{
	$('input[required="required"], textarea[required="required"], select[required="required"]').each(function(){
		if(typeof $(this).attr('placeholder') !== 'undefined' )
			$(this).attr('placeholder', $(this).attr('placeholder') + ' * ');
	});	
	$('label.required').each(function(){
		$(this).html($(this).html() + ' <span class="star">*</span>');
	});
}

function loadDocumentFrontendFiltersOptions()
{
	var classe = 0;
	var boat = 0;
	var skipper = 0;
	
	if($('#document_frontend_filter #document_frontend_filter_classe').val() > 0)
		classe = $('#document_frontend_filter #document_frontend_filter_classe').val();
	
	if($('#document_frontend_filter #document_frontend_filter_boat').val() > 0)
		boat = $('#document_frontend_filter #document_frontend_filter_boat').val();
	
	if($('#document_frontend_filter #document_frontend_filter_skipper').val() > 0)
		skipper = $('#document_frontend_filter #document_frontend_filter_skipper').val();
	
	var url = Routing.generate('ajax_frontend_document_load_filters_option', {classe: classe, boat: boat, skipper: skipper}); 
	
	$.ajax({
		method: "GET",
		url: url,
		dataType: "json",
		success: function(datas){
			$("#document_frontend_filter #document_frontend_filter_classe").empty().select2({
			    data: datas.classes,
			    allowClear: true				   
			});
			
			$("#document_frontend_filter #document_frontend_filter_boat").empty().select2({
			    data: datas.boats,
			    allowClear: true				   
			});
			
			$("#document_frontend_filter #document_frontend_filter_skipper").empty().select2({
			    data: datas.skippers,
			    allowClear: true				   
			});
		}
	});
}

/*
$.ajax({
method: "GET",
url: url,
dataType: "json",
success: function(families){
	var values = new Array({id:'', text:''});
	
	if(parentFamily != ''){    					
		$.each( families, function( i, family ) {
			values.push({id:family, text:i});
		});

	} else {
		$.each( families, function( i, family ) {							
			var families_child = new Array();
			$.each( family, function( k, child ) {
				families_child.push({id:child, text:k});
			});
				
			values.push({text:i, children:families_child});
		});					
	}
	
	$("#filter-form #product_filter_family").select2().empty()

	$("#filter-form #product_filter_family").select2({
	    data: values,
	    allowClear: true				   
	});

	if(family_select != null)
		$("#filter-form #product_filter_family").val(family_select).trigger("change");
}
});*/